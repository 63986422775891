<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
      </div>
      <div class="card-toolbar">
        <div class="me-2" v-if="permission.isDownloadJobTicketAllowed">
            <a href="javascript:void()" class="btn btn-purple" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
              <i class="bi bi-file-earmark-arrow-down fs-4"></i>
              {{ ordersConstants.DOWNLOAD_JOB_TICKET_BTN }}
              <span class="svg-icon svg-icon-5 m-0">
                <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
              </span>
            </a>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-purple fw-bold py-4 w-350px fs-6" data-kt-menu="true">
            <div class="menu-item px-5">
              <a href="javascript:void(0);" class="menu-link px-5" @click.prevent="downloadBatchJoBTicket('all')"><i class="bi bi-file-earmark-arrow-down fs-4"></i>&nbsp; {{ ordersConstants.DOWNLOAD_ALL_JOB_TICKET_BTN }}</a>
            </div>
            <div class="menu-item px-5">
              <a href="javascript:void(0);" class="menu-link px-5" @click.prevent="downloadBatchJoBTicket('selected')"><i class="bi bi-clock-history fs-4"></i>&nbsp; {{ ordersConstants.DOWNLOAD_SELECTED_JOB_TICKET_BTN }}</a>
            </div>
          </div>
        </div>
        <div class="me-2" v-if="permission.isDownloadShipmentLabelAllowed">
          <a href="javascript:void()" class="btn btn-purple" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
            <i class="bi bi-file-earmark-arrow-down fs-4"></i>
            {{ ordersConstants.DOWNLOAD_SHIPMENT_LABEL }}
            <span class="svg-icon svg-icon-5 m-0">
              <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
            </span>
          </a>
          <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-purple fw-bold py-4 w-400px fs-6" data-kt-menu="true">
            <div class="menu-item px-5">
              <a href="javascript:void(0);" class="menu-link px-5" @click.prevent="downloadBatchShipmentLabel('all')"><i class="bi bi-file-earmark-arrow-down fs-4"></i>&nbsp; {{ ordersConstants.DOWNLOAD_ALL_SHIPMENT_LABEL }}</a>
            </div>
            <div class="menu-item px-5">
              <a href="javascript:void(0);" class="menu-link px-5" @click.prevent="downloadBatchShipmentLabel('selected')"><i class="bi bi-clock-history fs-4"></i>&nbsp; {{ ordersConstants.DOWNLOAD_SELECTED_SHIPMENT_LABEL }}</a>
            </div>
          </div>
        </div>
        <div class="me-2" v-if="permission.isDownloadPackagingSlipAllowed">
          <a href="javascript:void()" class="btn btn-purple" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
            <i class="bi bi-file-earmark-arrow-down fs-4"></i>
            {{ ordersConstants.DOWNLOAD_PACKAGING_SLIP }}
            <span class="svg-icon svg-icon-5 m-0">
              <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
            </span>
          </a>
          <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-purple fw-bold py-4 w-400px fs-6" data-kt-menu="true">
            <div class="menu-item px-5">
              <a href="javascript:void(0);" class="menu-link px-5" @click.prevent="downloadBatchPackagingSlip('all')"><i class="bi bi-file-earmark-arrow-down fs-4"></i>&nbsp; {{ ordersConstants.DOWNLOAD_ALL_PACKAGING_SLIP }}</a>
            </div>
            <div class="menu-item px-5">
              <a href="javascript:void(0);" class="menu-link px-5" @click.prevent="downloadBatchPackagingSlip('selected')"><i class="bi bi-clock-history fs-4"></i>&nbsp; {{ ordersConstants.DOWNLOAD_SELECTED_PACKAGING_SLIP }}</a>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end" >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="/media/icons/duotune/general/gen024.svg" />
          </span>
        </button>
        <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
          <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{generalConstants.FILTER_COLUMNS}}</div>
          </div>
          <div class="separator border-gray-200"></div>
          <div class="px-7 py-5">
            <div class="mb-10">
              <span v-for="header in tableHeader" :key="header.key">
              <el-checkbox
                :disabled="visibleFields.length == 1 && header.visible"
                v-model="header.visible" 
                v-if="header.key != 'shipmentlabel' && header.key != 'actions'"
                class="me-2"
                inline >
                {{ header.name }}
              </el-checkbox>
              </span>
            </div>
            <div class="d-flex justify-content-end">
              <button type="submit" @click="submitFilters(visibleFields)" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" > {{generalConstants.SAVE}} </button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-xl-12">
          <!--begin::Accordion-->
          <div class="accordion" id="kt_accordion_1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="kt_accordion_1_header_1">
                <button class="accordion-button fs-4 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_1" aria-expanded="false" aria-controls="kt_accordion_1_body_1">
                  <h4 class="fw-bolder m-0">Filters</h4>
                </button>
              </h2>
              <div id="kt_accordion_1_body_1" class="accordion-collapse collapse" aria-labelledby="kt_accordion_1_header_1" data-bs-parent="#kt_accordion_1">
                <div class="accordion-body">
                  <div class="d-flex align-items-center position-relative my-1">
                    <div class="row">
                      <div class="col-md-2">
                        <input type="text" v-model="searchParams.search" v-on:keyup.enter="handleSearch()" class="form-control me-2" placeholder="Search Order Product" />
                      </div>
                      <div class="col-md-2">
                        <el-select v-model="searchParams.partner" :placeholder="ordersConstants.TRADING_PARTNER" size="large">
                          <el-option value="">{{ordersConstants.TRADING_PARTNER}}</el-option>
                          <el-option v-for="partner in tradingPartners" :value="partner.trading_partner_id" :key="partner" :label="partner.user.name">
                            {{partner.user.name}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-3">
                        <el-date-picker
                          class="w-100 ml-4"
                          v-model="searchParams.searchByOrderDate"
                          type="daterange"
                          range-separator="To"
                          value-format="YYYY-MM-DD"
                          start-placeholder="Order date"
                          end-placeholder="Order date"
                          size="large"
                        />
                      </div>
                      <div class="col-md-3">
                        <el-date-picker
                          class="w-100 ml-4"
                          v-model="searchParams.searchByOrderDueDate"
                          type="daterange"
                          range-separator="To"
                          value-format="YYYY-MM-DD"
                          start-placeholder="Order Due date"
                          end-placeholder="Order Due date"
                          size="large"
                          :shortcuts="shortcuts"
                          unlink-panels
                        />
                      </div>
                      <div class="col-md-2">
                        <el-select v-model="searchParams.searchByOrderStatus" :placeholder="ordersConstants.ORDER_STATUS" size="large">
                          <el-option value="">{{ordersConstants.ORDER_STATUS}}</el-option>
                          <el-option v-for="status in orderStatus" :value="status.type" :key="status" :label="status.title">
                            {{status.title}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-4 mt-4">
                      <el-date-picker
                        class="w-100 ml-4"
                        v-model="searchParams.searchByProductionDueDate"
                        type="daterange"
                        range-separator="To"
                        value-format="YYYY-MM-DD"
                        start-placeholder="Production Due date"
                        end-placeholder="Production Due date"
                        size="large"
                      />
                    </div>
                      <div class="col-md-2 mt-4">
                        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
                          <button type="button" @click.prevent="handleSearch()" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
                        </el-tooltip>
                        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
                          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Accordion-->
        </div>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
      >
        <template v-slot:cell-shipmentlabel="{ row: data }">
          <el-checkbox
          size="large"
          @change="setDownloadShipmentLabel($event, data.product_id)"
          />
        </template>
        <template v-slot:cell-order_id="{ row: data }">
          <p class="mt-2 mb-0" v-if="permission.isViewAllowed">
            <a
              href="javascript:void(0)" class="fw-bolder text-gray-600 text-hover-primary"
              @click.prevent="openProductDetailPage(data.id)"
            >
              #{{ data.id }}</a
            >
          </p>
          <p v-else>
            #{{ data.id }}
          </p>
        </template>
        <template v-slot:cell-client_order_id="{ row: data }">
          <p class="mt-2 mb-0" v-if="permission.isViewAllowed">
            <a
              href="javascript:void(0)" class="fw-bolder text-gray-600 text-hover-primary"
              @click.prevent="openProductDetailPage(data.id)"
            >
              {{ data.client_order_id }}</a
            >
          </p>
          <p v-else>
            {{ data.client_order_id }}
          </p>
        </template>
        <template v-slot:cell-partner="{ row: data }">
          <div v-if="permission.isViewAllowed">
              <el-tooltip class="box-item" effect="dark" :content="((data.platform_name) ? data.platform_name + '<br>' : '') + ((data.trading_partner_user) ? '(' + data.trading_partner_user + ')' : '')" placement="top" raw-content>
                <a href="javascript:void(0)" class="fw-bolder text-gray-600 text-hover-primary" @click.prevent="openProductDetailPage(data.id)">
                    <img :src="setImage(data.partner_image_path)" class="w-25px ms-n1 rounded-circle" :alt="(data.platform_name) ? data.platform_name : ''">
                </a>
              </el-tooltip>
          </div>
          <div v-else>
            <el-tooltip class="box-item" effect="dark" :content="((data.platform_name) ? data.platform_name + '<br>' : '') + ((data.trading_partner_user) ? '(' + data.trading_partner_user + ')' : '')" placement="top" raw-content>
              <img :src="setImage(data.partner_image_path)" class="w-25px ms-n1 rounded-circle" :alt="(data.platform_name) ? data.platform_name : ''">
            </el-tooltip>
          </div>
        </template>
        <template v-slot:cell-customer_details="{ row: data }">
          <span
            v-if="
              data.customer_name || data.customer_phone || data.customer_email
            "
          >
            <dl
              class="row mb-0"
              v-if="data.customer_name || data.customer_phone"
            >
              <span v-if="data.customer_name"
                >{{ data.customer_name }}
                <span v-if="data.customer_phone">
                  <a
                    :href="`tel:${data.customer_phone}`"
                    class="text-gray-600 text-hover-primary mb-1"
                    >({{ data.customer_phone }})</a
                  ></span
                ></span
              >
            </dl>
            <dl class="row mb-0" v-if="data.customer_email">
              <span
                ><a
                  :href="`mailto:${data.customer_email}`"
                  class="text-gray-600 text-hover-primary mb-1"
                  >{{ data.customer_email }}</a
                ></span
              >
            </dl>
          </span>
          <span v-else> - </span>
        </template>
        <template v-slot:cell-order_date="{ row: data }">
          <span v-if="data.order_date">{{ formatDateTime(data.order_date) }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:cell-image="{ row: data }">
          <el-image
            style="width: 100px; height: 100px"
            :src="data.product_image[0]"
            :preview-src-list="data.product_image"
            :initial-index="0"
            :hide-on-click-modal=true
            @click="stopScrolling()"
            @close="closeImage()"
            fit="cover"
          >
            <template #error>
              <div class="image-slot">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="'/media/no-img.jpg'"
                  fit="cover"
                />
              </div>
            </template>
          </el-image>
        </template>
        <template v-slot:cell-productDetails="{ row: data }">
          <div class="d-flex align-items-center">
            <div>
              <a
                href="javascript:void(0)"
                class="fw-bolder text-gray-600 text-hover-primary"
                @click.prevent="openDetailPage(data.id, data.product_id)"
                >{{ data.product_name }}</a
              >
              <div class="fs-7 text-muted" v-if="!!data.product_sku">
                <template v-if="!!data.reference_sku_code">
                  <span class="text-gray-800">{{ generalConstants.SKU }}:</span> {{ data.product_sku }}
                </template>
                <template v-if="!!data.reference_sku_code"><br>
                <span class="text-gray-800">{{ constants.product.PRODUCT_SKU }}:</span> <el-tooltip class="box-item" effect="dark" :content="data.sku_status == 'Matched' ? ordersConstants.SEPRATED_PRODUCT_SKU + ': ' + data.separated_product_sku : data.sku_status == 'Unmatched' ? 'SKU not found in system!' : 'SKU rejected'" placement="top" >
                  <span :class="data.sku_status == 'Matched' ? 'badge badge-light-success' : data.sku_status == 'Unmatched' ? 'badge badge-light-warning' : 'badge badge-light-danger'">{{ data.reference_sku_code }}</span>
                </el-tooltip>
                <el-tooltip class="box-item" effect="dark" :content="ordersConstants.SEPRATED_PRODUCT_SKU + ': ' + data.separated_product_sku" placement="top" raw-content>
                  <i class="bi bi-question-circle-fill"></i>
                </el-tooltip>
                </template>
                <template v-else>
                  <span class="text-gray-800">{{ constants.product.PRODUCT_SKU }}:</span> {{ data.reference_sku_code }}
                </template>
              </div>
              <div class="fs-7 text-muted" v-if="!!data.product_weight">
                {{ ordersConstants.WEIGHT }}: {{ data.product_weight }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:cell-mappedSKUs="{ row: data }">
          <div class="d-flex align-items-center">
            <div>
              <div class="fs-7 text-muted" v-if="!!data.reference_sku_code">
                <template v-if="data.reference_sku_code">
                  <div class="">
                  <el-tooltip class="box-item" effect="dark" :content="ordersConstants.SEPRATED_PRODUCT_SKU + ': ' + data.separated_product_sku" placement="top" >
                    <span><span class="text-gray-800">{{ ordersConstants.PRODUCT_SKU }}:</span> {{data.reference_sku_code}} <i class="bi bi-question-circle-fill"></i></span>
                  </el-tooltip>
                  </div>
                </template>
                <template v-if="data.reference_art_sku_code">
                  <el-tooltip class="box-item" effect="dark" :content="ordersConstants.SEPRATED_ART_SKU + ': ' + data.separated_art_sku" placement="top" >
                    <span><span class="text-gray-800">{{ ordersConstants.ART_SKU }}:</span> {{data.reference_art_sku_code}} <i class="bi bi-question-circle-fill"></i></span>
                  </el-tooltip>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:cell-shippingTypeAndMethods="{ row: data }">
          <span v-if="data.shipping_method || data.shipping_type">
            <span>{{ data.shipping_method }} </span> <span>{{ data.shipping_type }}</span>
          </span>
          <span v-else>-</span>
        </template>
        <template v-slot:cell-orderstatus="{ row: data }">
          <span class="badge" :class="`badge badge-light-${data.order_product_status_badge}`">
            {{data.order_product_status_title}}
            <span v-if="data.order_product_status_type == 'shipped' || data.order_product_status_type == 'completed'">
              <i class="bi bi-check-circle-fill text-success fs-3"></i>
            </span>
          </span>
        </template>
        <template v-slot:cell-productiondue="{ row: data }">
          <span v-if="data.product_production_due_date">{{ formatDate(data.product_production_due_date) }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:cell-quantity="{ row: data }">
          <span class="badge bg-primary">{{ data.product_quantity }}</span>
        </template>
        <template v-slot:cell-price="{ row: data }">
          {{ formatPrice(data.product_price) }}
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isViewAllowed || permission.isUpdateOrderProductStatusAllowed || permission.isModifyShipmentLableAllowed || permission.isDownloadShipmentLabelAllowed || permission.isDownloadJobTicketAllowed">
            <a href="javascript:void(0);" class="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" :id="'menuAction' + data.product_id" >Actions
              <span class="svg-icon svg-icon-5 m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                </svg>
              </span>
            </a>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-300px py-4" data-kt-menu="true">
                <div class="menu-item px-3" v-if="permission.isViewAllowed">
                  <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="openDetailPage(data.id, data.product_id)"><i class="bi bi-eye"></i> &nbsp; {{ generalConstants.VIEW }}</a>
                </div>
                <div class="menu-item px-3"  v-if="permission.isUpdateOrderProductStatusAllowed" >
                  <a href="javascript:void(0);" class="menu-link px-3" data-bs-toggle="modal" data-bs-target="#updateOrderProductStatusModal" @click.prevent="manageOrderProductstatus(data.product_id, data.order_product_status_id, data.order_id)"><i class="bi bi-pencil"></i> &nbsp; Update Order Product Status</a>
                </div>
                <!-- <div class="menu-item px-3" v-if="permission.isModifyShipmentLableAllowed && data.order_product_status_type != 'shipped'">
                  <div v-if="data.product_quantity > 0">
                    <a href="javascript:void(0);" class="menu-link px-3" data-bs-toggle="modal" data-bs-target="#shipment_modal" @click.prevent="manageShipmentLabel(data)"><i class="bi bi-truck"></i> &nbsp; {{ ordersConstants.MODIFY_DOWNLOAD_SHIPMENT_LABEL }}</a>
                  </div>
                  <div v-else>
                    <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="manageShipmentLabel(data)"><i class="bi bi-truck"></i> &nbsp; {{ ordersConstants.MODIFY_DOWNLOAD_SHIPMENT_LABEL }}</a>
                  </div>
                </div> -->
                <!-- <div class="menu-item px-3" v-if="permission.isDownloadShipmentLabelAllowed">
                  <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="manageShipmentLabel(data, true)"><i class="bi bi-download"></i> &nbsp; {{ ordersConstants.DOWNLOAD_SHIPMENT_LABEL }}</a>
                </div> -->
                <div class="menu-item px-3" v-if="permission.isDownloadPackagingSlipAllowed">
                  <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="downloadBatchPackagingSlip('individual',data.product_id)"><i class="bi bi-download"></i> &nbsp; {{ ordersConstants.DOWNLOAD_PACKAGING_SLIP }}</a>
                </div>
                <div class="menu-item px-3" v-if="permission.isDownloadJobTicketAllowed">
                  <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="downloadBatchJoBTicket('individual',data.product_id)"><i class="bi bi-download"></i> &nbsp; {{ ordersConstants.DOWNLOAD_JOB_TICKET }}</a>
                </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </Datatable>
    </div>
    <UpdateOrderProductStatus :orderProductId="orderProductId" :orderId="orderId" :orderProductStatusId="statusId" ref="updateOrderProductStatusProps" @statusUpdate="getOrderProducts"></UpdateOrderProductStatus>
    <div v-if="permission.isDownloadShipmentLabelAllowed">
      <GenerateOrderShipmentModal
        @getDetails="getOrderProducts"
        ref="prepareshipmentData"
      ></GenerateOrderShipmentModal>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { formatPrice, formatDate, formatText, formatDateTime } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import {
  createOrderPoductFiles
} from "@/composable/order.js";
import moment from "moment";
import UpdateOrderProductStatus from "@/components/modals/forms/UpdateOrderProductStatus.vue";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";
import GenerateOrderShipmentModal from "@/components/modals/forms/GenerateOrderShipmentModal.vue";
import axios from "axios";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default {
  name: "ordersProducts",
  components: {
    Datatable,
    UpdateOrderProductStatus,
    GenerateOrderShipmentModal
  },
  setup() {
    let total = ref(0);
    const tableData = ref([]);
    const loading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const tradingPartners = ref([]);
    const orderStatus = ref([]);
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const constants = globalConstant;
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const pageType = ref("");
    const orderProductId = ref(0);
    const store = useStore();
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const searchData = ref(store.getters.getOrderSearch(moduleName.value));
    const checkedOrderProductIds = ref([]);
    const individualOrderProductId = ref([]);
    const getAllOrderProductIds = ref([]);
    const prepareshipmentData = ref(null);
    const statusId = ref(0);
    const orderId = ref(0);
    const updateOrderProductStatusProps = ref(null);
    const searchParams = reactive({
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
      page_type: "order_listing",
      partner: searchData.value.searchByTradingPartner,
      search: searchData.value.search,
      searchByOrderDate: searchData.value.searchByOrderDate,
      searchByOrderDueDate: searchData.value.searchByOrderDueDate,
      searchByProductionDueDate: searchData.value.searchByProductionDueDate,
      searchByOrderStatus: searchData.value.searchByOrderStatus,
      isPendingOrder:0
    })

    const shipmentData = reactive({
      loading: false,
      trading_partner_id: 0,
      customer_name: '',
      order_shipment_id: 0,
      order_id: 0,
      order_product_id: 0,
      shipping_method: '',
      shipping_type: '',
      total_weight: 0,
      tracking_no: '',
      internal_packages: [],
      external_packages: []
    });

    //Prepare Shipment Label form data
    const manageShipmentLabel = async(productData, isDownload) => {
      document.getElementById('kt_content_container').click();
      prepareshipmentData.value.prepareshipmentData(productData, isDownload);
    }

    //Get multiple order products for download Shipment Label
    const setDownloadShipmentLabel = (val, orderProductId) => {
      if(val) {
        checkedOrderProductIds.value.push(orderProductId);
      } else {
        checkedOrderProductIds.value.splice(checkedOrderProductIds.value.indexOf(orderProductId), 1);
      }
    }

    //Download batch Shipment Label
    const downloadBatchShipmentLabel = (val) => {
      let orderProductIds = [];
      if (val == 'selected') {
        if(checkedOrderProductIds.value.length == 0) {
          notificationFire('Please select at least one order product to download shipment label.', "error");
          return false;
        }
        orderProductIds = checkedOrderProductIds.value;
      } else if (val == 'all') {
        orderProductIds = getAllOrderProductIds.value;
      }
      loading.value = true;
      ApiService.post("template-management/download-batch-shipment-label", {
        orderProductIds: orderProductIds
      })
      .then(({ data }) => {
        if (data) {
          notificationFire(data.message, generalConstants.NOTIFY_SUCCESS);
          let path = process.env.VUE_APP_API_URL;
          path = path.replace('/api/v1', '');
          window.open(path + data.data, '_blank');
        }
        loading.value = false;
      })
      .catch((error) => {
      loading.value = false;
        let message = "";
        if (typeof error.response !== "undefined") {
          message = error.response.data.message;
        } else if (typeof error.message !== "undefined") {
          message = error.message;
        } else {
          message = generalConstants.NOTIFY_WRONG_MESSAGE;
        }
        notificationFire(message, generalConstants.NOTIFY_ERROR);
      });
    };

    //Download batch Packaging Slip
    const downloadBatchPackagingSlip = (val,orderProductId = 0) => {
      let orderProductIds = [];
      if (val == 'selected') {
        if(checkedOrderProductIds.value.length == 0) {
          notificationFire('Please select at least one order product to download packaging slip.', "error");
          return false;
        }
        orderProductIds = checkedOrderProductIds.value;
      }else if(val == 'individual' && orderProductId > 0){
        individualOrderProductId.value = [];
        individualOrderProductId.value.push(orderProductId);
        orderProductIds = individualOrderProductId.value;
      }else if (val == 'all') {
        orderProductIds = getAllOrderProductIds.value;
      }
      loading.value = true;
      axios({
          url: 'template-management/download-order-product-packaging-slip',
          method: 'POST',
          data: {
            orderProductIds: orderProductIds
          },
          responseType: 'arraybuffer',
      }).then((response) => {
          let blob = new Blob([response.data], {
              type: 'application/pdf'
          })
          let link = document.createElement('a')
          link.setAttribute('target','_blank')
          link.href = window.URL.createObjectURL(blob)
          link.click()
          loading.value = false;
      })
      .catch((error) => {
        let response = error;
        if (response != "") {
          notificationFire('Packaging slip data not found', "error");
        }
        loading.value = false;
      });
      return false;
    };

    let scroll = function (e) { e.preventDefault() };

    const stopScrolling = () => {
      document.addEventListener("wheel", scroll, { passive:false })
    }

    const closeImage = () => {
      document.removeEventListener("wheel", scroll)
    }

    const shortcuts = [
      {
        text: 'Last week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: 'Last month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: 'Last 3 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]

    const tableHeader = reactive([
      {
        name: " ",
        key: "shipmentlabel",
        visible: true,
      },
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        visible: true,
      },
      {
        name: ordersConstants.PARTNER,
        key: "partner",
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_ID,
        key: "order_id",
        visible: true,
        sortable: true,
      },
      {
        name: ordersConstants.PARTNER_ORDER_ID,
        key: 'client_order_id',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.CUSTOMER_DETAILS,
        key: "customer_details",
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_DATE,
        key: "order_date",
        visible: true,
        sortable: false,
      },
      {
        name: generalConstants.IMAGE,
        key: "image",
        sortable: true,
        visible: false,
      },
      {
        name: ordersConstants.PRODUCT_DETAILS,
        key: "productDetails",
        sortable: false,
        visible: true,
      },
      {
        name: ordersConstants.MAPPED_SKUS,
        key: "mappedSKUs",
        sortable: false,
        visible: false,
      },
      {
        name: ordersConstants.SHIPPING_AND_METHOD,
        key: "shippingTypeAndMethods",
        sortable: false,
        visible: false,
      },
      {
        name: ordersConstants.ORDER_STATUS,
        key: "orderstatus",
        sortable: false,
        visible: true,
      },
      {
        name: ordersConstants.PRODUCTION_DUE,
        key: "productiondue",
        sortable: false,
        visible: false,
      },
      {
        name: generalConstants.QUANTITY,
        key: "quantity",
        sortable: false,
        visible: true,
      },
      {
        name: generalConstants.PRICE,
        key: "price",
        sortable: false,
        visible: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        sortable: false,
        visible: true,
      },
    ]);
    const visibleFields = computed(() => {
      return tableHeader.filter((header) => header.visible);
    });
    const permission = reactive({
      isViewAllowed: false,
      isDownloadShipmentLabelAllowed: false,
      isDownloadPackagingSlipAllowed: false,
      isUpdateOrderProductStatusAllowed: false,
      isDownloadJobTicketAllowed: false,
      isModifyShipmentLableAllowed: false
    })
    const manageOrderProductstatus = (id, status, order_id) => {
      orderProductId.value = id;
      statusId.value = status;
      orderId.value = order_id;
      updateOrderProductStatusProps.value.updateProps(statusId.value);
    }
    const submitFilters = async (filters) => {
      if (!tableHeader) return;
      let filterData = {
        orderProductFilters: tableHeader.map(({ key, visible }) => ({
          [key]: visible,
        })),
      };
      ApiService.post("users/save-filters", filterData)
        .then((response) => {
          if (response.data) {
            let status = response.status;
            let message = response.data.message;
            if (status == 200 || status == 201) {
              notificationFire(message, "success");
            } else {
              notificationFire(message, "error");
            }
          }
        })
        .catch((error) => {
          loading.value = false;
          loadingback.value = false;
          let message = error.message;
          notificationFire(message, "error");
        });
    };

    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    };

    const handleCurrentChange = async(number) => {
      getAllOrderProductIds.value = [];
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      await getOrderProducts();
      MenuComponent.reinitialization();
    };

    const openDetailPage = (orderId, orderProductId) => {
      router.push('/order-products/product/'+orderId+'/'+orderProductId)
    };

    const openProductDetailPage = (orderId) => {
      router.push('/order-products/'+orderId)
    }

    //Get all order products
    const getOrderProducts = async () => {
      loading.value = true;
      await ApiService.query("order-products-list", { params: searchParams })
        .then(({ data }) => {
          if(data.data.filter) {
            var filterData = data.data.filter;
            tableHeader.forEach((options, headerKey) => {
              let key = options.key;
              if (filterData[key] !== undefined) {
                tableHeader[headerKey].visible = filterData[key];
              }
            });
          }
          if (data.data.data.length) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.data
            );
            total.value = data.data.total;
            updateProps.value.updateProps(
              total.value,
              searchParams.recordsPerPage,
              searchParams.pageNub
            );
          } else {
            tableData.value = [];
            total.value = 0;
            updateProps.value.updateProps(
              total.value,
              searchParams.recordsPerPage,
              searchParams.pageNub,
              generalConstants.NO_DATA_FOUND
            );
          }
          if (tableData.value.length > 0) {
            let i = 0;
            tableData.value.forEach((value) => {
              value['index'] = i;
              if(value.downloaded_files) {
                value.downloaded_files = JSON.stringify(value.downloaded_files);
              }   
              value["product_image"] = createOrderPoductFiles(value)["productFilesInfo"];
              getAllOrderProductIds.value.push(value['product_id']);
            });
          }
          setCurrentPageBreadcrumbs(
            formatText(route.name) + " (" + total.value + ")",
            [{ name: formatText(route.name), active: true }]
          );
          loading.value = false;
        })
        .catch((error) => {
          //Toast the error here
          tableData.value = [];
          total.value = 0;
          loading.value = false;
        });
    };

    //Get all active trading partners
    const getTradingPartners = async () => {
      loading.value = true;
      await ApiService.query("get-active-trading-partner")
        .then(({ data }) => {
          if (data.data) {
            tradingPartners.value = data.data;
          } else {
            tradingPartners.value = [];
          }
          loading.value = false;
        })
        .catch((error) => {
          //Toast the error here
          tradingPartners.value = [];
          loading.value = false;
        });
    };

    //Reset search value
    const resetSearch = async() => {
      getAllOrderProductIds.value = [];
      if (searchParams.search.length > 0) {
        searchParams.search = "";
      }
      searchParams.partner = "";
      searchParams.searchByOrderDate = "";
      searchParams.searchByOrderDueDate = "";
      searchParams.searchByProductionDueDate = "";
      searchParams.searchByOrderStatus = "";
      rememberSearch();
      await getOrderProducts();
      MenuComponent.reinitialization();
    };

    //Order product search event function
    const handleSearch = () => {
      getAllOrderProductIds.value = [];
      rememberSearch();
      handleCurrentChange(1);
    };

    //Download batch job ticket functionality
    const downloadBatchJoBTicket = (val,orderProductId = 0) => {
      let orderProductIds = [];
      if (val == 'selected') {
        if(checkedOrderProductIds.value.length == 0) {
          notificationFire('Please select at least one order product to download job ticket.', "error");
          return false;
        }
        orderProductIds = checkedOrderProductIds.value;
      }else if(val == 'individual' && orderProductId > 0){
        individualOrderProductId.value = [];
        individualOrderProductId.value.push(orderProductId);
        orderProductIds = individualOrderProductId.value;
      }else if (val == 'all') {
        orderProductIds = getAllOrderProductIds.value;
      }
      loading.value = true;
      axios({
          url: 'template-management/download-order-product-job-ticket',
          method: 'POST',
          data: {
            orderProductIds: orderProductIds
          },
          responseType: 'arraybuffer',
      }).then((response) => {
          let blob = new Blob([response.data], {
              type: 'application/pdf'
          })
          let link = document.createElement('a')
          link.setAttribute('target','_blank')
          link.href = window.URL.createObjectURL(blob)
          link.click()
          loading.value = false;
      })
      .catch((error) => {
        let response = error;
        if (response != "") {
          notificationFire('No Order Product found', "error");
        }
        loading.value = false;
      });
      return false;      
    }

    const badgeColor = (date) => {
        let daysDifference = moment(date, 'YYYY-MM-DD').diff(moment(new Date()).format("YYYY-MM-DD"), "days");
        let badge = '';
        if(daysDifference > 2) {
          badge = 'badge-light-success'
        } else if (daysDifference <= 2 && daysDifference >= 0) {
          badge = 'badge-light-warning'
        } else if (daysDifference < 0) {
          badge = 'badge-light-danger'
        }
      return badge; 
    };

    const rememberSearch = () => {
      let actionParams = {
          "search": searchParams.search,
          "searchByTradingPartner": searchParams.partner,
          "searchByOrderType": searchParams.orderType,
          "searchByOrderDate": searchParams.searchByOrderDate,
          "searchByOrderDueDate": searchParams.searchByOrderDueDate,
          "searchByOrderStatus": searchParams.searchByOrderStatus
      }
      store.dispatch(Actions.ORDER_SEARCH, {'module':moduleName.value,'params':actionParams});
    };

    const setImage = (image) => {
        if (image == null) {
            return '/media/avatars/blank.png';
        }
      return process.env.VUE_APP_API_URL + "../../../partner_image/" + image;
    };

    //Get all active statuses
    const getActiveStatusForOrders = async () => {
      loading.value = true;
      await ApiService.query("get-order-status")
      .then(({ data }) => {
        if (data.data) {
          orderStatus.value = data.data;
        } else {
          orderStatus.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        orderStatus.value = [];
        loading.value = false;
      });
    }

    //Set breadcrumbs and get the order products
    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        formatText(route.name),
        [{ name: formatText(route.name), active: true }]
      );
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("viewOrderProductDetails") || role == "Super Admin")
        permission.isViewAllowed = true;
      if (per.includes("downloadShipmentLabel") || role == "Super Admin")
        permission.isDownloadShipmentLabelAllowed = true;
      if (per.includes("downloadpackagingslip") || role == "Super Admin")
        permission.isDownloadPackagingSlipAllowed = true;
      if (per.includes("updateOrderProductStatus") || role == "Super Admin")
        permission.isUpdateOrderProductStatusAllowed = true;
      if (per.includes("modifyShipmentLabel") || role == "Super Admin")
        permission.isModifyShipmentLableAllowed = true;
      if (per.includes("downloadTicketOrder") || role == "Super Admin")
        permission.isDownloadJobTicketAllowed = true;
        
      pageType.value = (route.meta.pageType) ? route.meta.pageType : "";
      getTradingPartners();
      getActiveStatusForOrders();
      await getOrderProducts();
      MenuComponent.reinitialization();
    });

    return {
      tableData,
      formatPrice,
      formatDate,
      formatDateTime,
      getOrderProducts,
      total,
      handleSizeChange,
      handleCurrentChange,
      loading,
      searchParams,
      openDetailPage,
      visibleFields,
      submitFilters,
      tradingPartners,
      getTradingPartners,
      tableHeader,
      generalConstants,
      noDataFound,
      updateProps,
      resetSearch,
      ordersConstants,
      handleSearch,
      pageType,
      shortcuts,
      permission,
      badgeColor,
      manageOrderProductstatus,
      orderProductId,
      manageShipmentLabel,
      shipmentData,
      setDownloadShipmentLabel,
      downloadBatchShipmentLabel,
      prepareshipmentData,
      downloadBatchJoBTicket,
      statusId,
      orderId,
      updateOrderProductStatusProps,
      constants,
      openProductDetailPage,
      setImage,
      getActiveStatusForOrders,
      orderStatus,
      stopScrolling,
      closeImage,
      downloadBatchPackagingSlip
    };
  },
};
</script>
<style lang="scss">
.btn.btn-purple{
  background-color: #8618cbbf;
  border-color: #8618cbbf;
  color:#ffffff;
}
.btn.btn-purple i{color:#ffffff;}
.btn.btn-purple svg path{fill:#ffffff !important;}
.btn.btn-purple:hover,.btn.btn-purple:focus,.btn.btn-purple:active{
  background-color: #6911a1bf;
  border-color: #6911a1bf;
}
.menu-state-bg-light-purple .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-purple .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #f5e7ff96;
  color: #8618cbbf;
}
.el-popper.is-dark {
  text-align: center !important;
}
</style>