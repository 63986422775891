<template>
  <!--begin::Modal - Create Api Key-->
  <div class="modal fade" id="updateOrderProductStatusModal" tabindex="-1" aria-hidden="true" ref="updateOrderProductStatusModalRef">
    <!--begin::Modal dialog-->
    <div class="modal-dialog">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2>Update Status</h2>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Form-->
        <Form id="update_order_product" class="w-100" style="width: 100%" @submit="submit">
          <!--begin::Modal body-->
          <div class="modal-body">
            <!--begin::Input group-->
            <div class="d-flex flex-column fv-row">
              <!--begin::Label-->
              <label class="fs-5 fw-bold mb-2">Select Status</label>
              <!--end::Label-->
              <!--begin::Select-->
              <Field name="statusId" data-control="select2" data-hide-search="true" data-placeholder="Select Status" class="form-select" as="select" v-model="statusId">
                <option value="">Select Status</option>
                <option v-for="status in statusData" :value="status.id" :key="status">
                  {{status.title}}
                </option>
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="statusId" />
                </div>
              </div>
              <!--end::Select-->
            </div>
            <!--end::Input group-->
          </div>
          <!--end::Modal body-->
          <!--begin::Modal footer-->
          <div class="modal-footer">
            <!--begin::Button-->
            <button ref="submitButtonRef" type="submit" value="approve" id="update_status_submit" class="btn btn-primary">
              <span class="indicator-label"> Save </span>
              <span class="indicator-progress">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create Api Key-->
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { notificationFire } from "@/composable/notification.js";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "order-product-status-update",
  emit: ["statusUpdate"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    orderProductId: Number,
    orderId: Number,
    orderProductStatusId: Number
  },
  setup(props, { emit }) {
    const router = useRouter();
    const submitButtonRef = ref(null);
    const modalRef = ref(null);
    const updateOrderProductStatusModalRef = ref(null);
    const fullScreenLoading = ref(false);
    const statusData = ref([]);
    const statusId = ref(props.orderProductStatusId);

    const updateProps = (status) => {
        statusId.value = status;
    }

    const getOrderProductStatus = () => {
      fullScreenLoading.value = true;
      ApiService.query("orderproductstatus")
        .then((response) => {
          let status = [];
          response.data.data.forEach((obj) => {
            status.push({
              id: obj.id,
              title: obj.title,
            });
          });
          statusData.value = status;
          fullScreenLoading.value = false;
        })
        .catch((error) => {
          // fullscreenLoading.value = false;
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            if (status == 404) {
              router.push("/order-products");
            }
          }
          //Call common error function here
        });
    };

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      ApiService.post("update-order-product-status", {"status_id":statusId.value,"order_product_id":props.orderProductId, "order_id": props.orderId})
        .then((response) => {
          if (response.data) {
            let status = response.status;
            let message = response.data.message;
            if (status == 200 || status == 201) {
              notificationFire(message, "success");
              emit("statusUpdate", true);
            }
          }
          submitButtonRef.value.disabled = false;
          submitButtonRef.value.setAttribute("data-kt-indicator", "off");
          hideModal(updateOrderProductStatusModalRef.value);
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          } else {
            let message = error.message;
            notificationFire(message, "error");
          }
          submitButtonRef.value.disabled = false;
          submitButtonRef.value.setAttribute("data-kt-indicator", "off");
        });
    };

    onMounted(() => {
      getOrderProductStatus();
    });

    return {
      submit,
      submitButtonRef,
      modalRef,
      updateOrderProductStatusModalRef,
      getOrderProductStatus,
      fullScreenLoading,
      statusData,
      statusId,
      updateProps
    };
  },
});
</script>
